import { OwnUpSubheadlineMedium } from '@rategravity/own-up-component-library-new';
import React from 'react';
import { PostSnippet } from '../../types';
import { ArticlesList } from '../articles-list';
import { PageTitleContainer } from '../page-title-container';
import { PaginationContainer } from '../pagination';

export interface BlogList {
  numPages: number;
  page: number;
  posts: PostSnippet[];
}

export const BlogList = ({ numPages, page, posts }: BlogList) => (
  <React.Fragment>
    <PageTitleContainer>
      <OwnUpSubheadlineMedium variant="h1"> Page {page} </OwnUpSubheadlineMedium>
    </PageTitleContainer>
    <ArticlesList posts={posts} context="normal" />
    <PaginationContainer numPages={numPages} page={page} />
  </React.Fragment>
);
