import { graphql } from 'gatsby';
import React from 'react';
import { BackToHomepage } from '../../components/back-to-homepage';
import { BlogList } from '../../components/blog-list';
import { Layout } from '../../components/layout';
import { SEO } from '../../components/seo';
import { mapPostData } from '../../module/map-post-data';
import { Post, PostSnippet } from '../../types';

interface QueryData {
  data: {
    allMarkdownRemark: {
      nodes: Post[];
    };
  };
  pageContext: {
    skip: number;
    numPages: number;
    currentPage: number;
  };
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          tags
          imgAlt
          publishedDate
        }
      }
    }
  }
`;

export const Page = ({ data, pageContext }: QueryData) => {
  const posts: PostSnippet[] = data.allMarkdownRemark.nodes.map(mapPostData);

  const { numPages, currentPage } = pageContext;

  return (
    <>
      <SEO
        title={`Page ${currentPage}`}
        description={`More articles about mortgages and home-buying.`}
      />
      <Layout>
        <BackToHomepage />
        <BlogList numPages={numPages} posts={posts} page={currentPage} />
      </Layout>
    </>
  );
};

//eslint-disable-next-line import/no-default-export
export default Page;
